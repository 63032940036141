import React from "react"
import HeadingL from "../../components/shared/HeadingL"
import HeadingM from "../../components/shared/HeadingM"
import Layout from "../../components/one/Layout"
import SEO from "../../components/Seo"
import About from "../../components/shared/usage/About"
import Fee from "../../components/shared/usage/Fee"
import Procedure from "../../components/shared/usage/Procedure"
import { PAGE } from "../../constants"

const usage = () => {
  return (
    <Layout>
      <SEO title={PAGE.title.usage} siteTitle="one" />
      <HeadingL title={PAGE.title.usage} target="one" />
      <HeadingM title="ご利用について" target="one" />
      <About />
      <HeadingM title="ご利用料金" target="one" />
      <Fee target="one" />
      <HeadingM title="ご利用手順" target="one" />
      <Procedure target="one" />
    </Layout>
  )
}

export default usage
